var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("CSpinner", { attrs: { size: "sm", color: "primary" } })
        : _vm._e(),
      _vm._l(_vm.permissionList, function (p) {
        return _c("ul", { key: p.id, staticClass: "list-group" }, [
          _c("li", { staticClass: "list-group-item rounded-0" }, [
            _c("div", { staticClass: "custom-control custom-checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.restPermissions,
                    expression: "restPermissions",
                  },
                ],
                staticClass: "custom-control-input",
                attrs: {
                  id: `permission_${p.id}`,
                  type: "checkbox",
                  disabled:
                    _vm.hasDeliveryService &&
                    p.name === "manage-delivery-areas",
                },
                domProps: {
                  value: p,
                  checked: Array.isArray(_vm.restPermissions)
                    ? _vm._i(_vm.restPermissions, p) > -1
                    : _vm.restPermissions,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.restPermissions,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = p,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.restPermissions = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.restPermissions = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.restPermissions = $$c
                    }
                  },
                },
              }),
              _c(
                "label",
                {
                  staticClass: "cursor-pointer d-block custom-control-label",
                  attrs: { for: `permission_${p.id}` },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm._f("normalizeSentence")(p.name))),
                  ]),
                  _c("span", { staticClass: "small font-italic text-muted" }, [
                    _vm._v(_vm._s(p.description)),
                  ]),
                ]
              ),
            ]),
          ]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }